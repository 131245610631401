* {
  margin: 0;
}
body {
  background-color: #4787c7;
}
p,
h6,
h5,
h4,
h3,
h2,
h1,
span {
  font-family: "Inter", sans-serif;
  font-family: "Nunito", sans-serif;
}
/* SCROLL */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* FIM SCROLL */

.div-background {
  background-color: rgba(142, 199, 238, 0.22);
  border-top-left-radius: 80%;
  height: 100%;
  padding: 10% 80% 80% 100%;
  position: fixed;
  z-index: -99;
}

/* TELA LOGIN */
.login {
  padding: 40px 0 80px 0;
}

.login .message-error {
  padding-bottom: 15px;
  text-align: center;
}

.login .div-images {
  display: flex;
  margin-bottom: -145px;
  margin-left: -100px;
  z-index: 2;
}

.login .image-1 {
  width: 300px;
}

.login .image-2 {
  width: 400px;
  margin: 0 0 150px 5px;
}

@media (max-width: 800px) {
  .login .image-1 {
    width: 200px;
  }

  .login .image-2 {
    width: 300px;
  }

  .login .div-images {
    margin-left: 10px;
  }
}

@media (max-width: 565px) {
  .login .image-1 {
    width: 35vw;
  }

  .login .image-2 {
    width: 45vw;
  }

  .login .div-images {
    margin-left: 10px;
  }
}

.login .card-login {
  padding-top: 80px;
}
/* FIM TELA LOGIN */

/* TELA HOME */
.home {
  padding-top: 85px;
}

.home .main {
  /* min-height: 72vh; */
  width: 60vw;
  display: block;
  margin: auto;
}

.home .header-video {
  margin-bottom: -70px;
}

.home .header-video .event-summary {
  color: #fff !important;
}

.home .header-video .event-summary .MuiButtonBase-root {
  color: #3b72a8;
  font-size: 14px;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 5px 10px;
}

.home .div-image-brain {
  transform: scaleX(-1);
}

.home .div-image-brain img {
  width: 200px;
}

.home .div-video {
  height: calc((60vw / 16) * 9);
  background-color: #000;
  margin-bottom: 50px;
  border: 8px solid #fff;
  border-radius: 15px;
  display: block;
  margin: auto;
}

.home .div-video iframe{
  border-radius: 15px;
}

.home .div-video .MuiCircularProgress-root {
  display: block;
  margin: auto;
  margin-top: 25%;
  color: #fff;
}

.home .image-theme {
  width: 450px;
  display: block;
  margin: auto;
}

.home .text-conube-day {
  color: #fff;
  margin-top: 30px;
  text-align: center;
}

.home .logo-conube {
  float: "left";
  margin: 10px 0 10px 0;
}

@media (max-width: 600px) {
  .home .image-theme {
    width: 250px;
  }
  .home .header-video .event-summary .MuiButtonBase-root {
    margin-bottom: 40px;
  }
  .home .div-image-brain img {
    width: 108px;
    margin-bottom: 25px;
  }

  .home .header-video {
    margin-top: 50px !important;
  }

  .home .header-video .event-summary {
    margin-top: -20px !important;
  }
}

@media (max-width: 900px) {
  .home .div-video {
    height: calc((90vw / 16) * 9);
    width: auto;
  }

  .home .main {
    width: 90vw;
  }
}
/* FIM TELA HOME */
